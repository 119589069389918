import "./LanguagesGallery.scss"
import React from "react"
import Image from "gatsby-image"
import { Container, Row, Col } from "reactstrap"

const LanguagesGallery = ({ images, ...props }) => (
  <Container id="language-gallery" className="mt-n5" {...props}>
    <Row className="align-items-center">
      <Col xs={3} className="p-4">
        <Image className="mr-auto" fluid={images[0]} alt="Sprachen" />
      </Col>
      <Col xs={3} className="p-4">
        <Image className="m-auto" fluid={images[1]} alt="Sprachen" />
      </Col>
      <Col xs={3} className="p-4">
        <Image className="m-auto" fluid={images[2]} alt="Sprachen" />
      </Col>
      <Col xs={3} className="p-4">
        <Image className="ml-auto" fluid={images[3]} alt="Sprachen" />
      </Col>
    </Row>
    <Row className="align-items-center">
      <Col xs={4} className="p-4">
        <Image className="mr-auto" fluid={images[4]} alt="Sprachen" />
      </Col>
      <Col  xs={4} className="p-4">
        <Image className="m-auto" fluid={images[5]} alt="Sprachen" />
      </Col>
      <Col  xs={4} className="p-4">
        <Image className="ml-auto" fluid={images[6]} alt="Sprachen" />
      </Col>
    </Row>
    <Row className="align-items-center">
      <Col  xs={4} className="p-4">
        <Image className="mr-auto" fluid={images[7]} alt="Sprachen" />
      </Col>
      <Col  xs={4} className="p-4">
        <Image className="m-auto" fluid={images[8]} alt="Sprachen" />
      </Col>
      <Col  xs={4} className="p-4">
        <Image className="ml-auto"  fluid={images[9]} alt="Sprachen" />
      </Col>
    </Row>
  </Container>
)

export default LanguagesGallery
