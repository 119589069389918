import React from "react"
import { graphql } from "gatsby"
import { compose, prop } from "ramda"
import { Container, Row } from "reactstrap"
import {
  getEdgesForQuery,
  getImageQueryFluidData,
  getQueryData,
  getSectionDataFor,
} from "@utils/utils"

/**
 * import Sections
 */

import MainLayout from "@layouts/MainLayout/MainLayout"
import SEO from "@components/shared/SEO/SEO"
import HeaderSection from "@components/shared/HeaderSection/HeaderSection"
import CopySection from "@components/shared/CopySection/CopySection"
import GridList from "@components/shared/GridList/GridList"
import ContactSection from "@components/shared/ContactSection/ContactSection"
import TypewriterSection from "@components/technologies/TypewriterSection/TypewriterSection"
import LanguagesGallery from "@components/technologies/LanguagesGallery/LanguagesGallery"

/**
 * import pictures
 */

import imageTechnologies from "@assets/pictures/buehne_technologien.svg"

import ic_web_applikationen from "@assets/icons/ic_web-applikationen.svg"
import ic_mobile_applikationen from "@assets/icons/ic_mobile-applikationen.svg"
import ic_cloud_solutions from "@assets/icons/ic_cloud-solutions.svg"
import ic_desktop_applikationen from "@assets/icons/ic_desktop-applikationen.svg"

/**
 * quering data
 */
export const query = graphql`
  query TechnologiesSectionIndexQuery {
    ui: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/ui" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            head
            rotatingTail
            section
          }
        }
      }
    }
    content: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/technologies" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            title
            section
            blocks {
              image
              label
              text
            }
          }
          internal {
            content
          }
        }
      }
    }
    languages: allImageSharp(
      filter: { fluid: { originalName: { glob: "content_sprachen_*" } } }
      sort: { fields: resize___originalName }
    ) {
      edges {
        node {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    footer: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/ui" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            section
            title
            subtitle
            cta
            ctaUrl
          }
          internal {
            content
          }
          html
        }
      }
    }
  }
`

const TechnologyPage = ({ data }) => {
  const content = compose(getQueryData, getEdgesForQuery("content"))(data)
  const ui = compose(getQueryData, getEdgesForQuery("ui"))(data)

  const uiTypewriterData = getSectionDataFor("ui-section-typewriter", ui)

  const headerSectionData = getSectionDataFor(
    "technologies-section-header",
    content
  )
  const overviewSectionData = getSectionDataFor(
    "technologies-section-overview",
    content
  )

  const whatWeDoSectionData = getSectionDataFor(
    "technologies-section-what-we-do",
    content
  )
  const whatWeDoBlockList = prop("blocks", whatWeDoSectionData)
  const whatWeDoImageList = {
    ic_web_applikationen,
    ic_mobile_applikationen,
    ic_cloud_solutions,
    ic_desktop_applikationen,
  }

  const languagesSectionData = getSectionDataFor(
    "technologies-section-languages",
    content
  )
  const languagesGalleryList = compose(
    getImageQueryFluidData,
    getEdgesForQuery("languages")
  )(data)

  const footer = compose(getQueryData, getEdgesForQuery("footer"))(data)
  const contactSectionData = getSectionDataFor(
    "ui-section-footer-contact",
    footer
  )

  return (
    <MainLayout>
      <SEO />
      <HeaderSection data={headerSectionData} image={imageTechnologies} />
      <CopySection
        anchor={"overview"}
        className="bg-blue-lighter"
        data={overviewSectionData}
        dotColor="yellow"
      />
      <CopySection
        anchor={"what-we-do"}
        className="bg-blue-bright"
        data={whatWeDoSectionData}
        dotColor="green"
      >
        <Container className="mt-n5">
          <Row>
            <GridList items={whatWeDoBlockList} imageList={whatWeDoImageList} />
          </Row>
        </Container>
      </CopySection>
      <TypewriterSection data={uiTypewriterData} />
      <CopySection
        anchor={"languages"}
        className="bg-white"
        data={languagesSectionData}
        dotColor="green"
      >
        <>
          <LanguagesGallery images={languagesGalleryList} className="mt-4" />
        </>
      </CopySection>
      <ContactSection data={contactSectionData} />
    </MainLayout>
  )
}

export default TechnologyPage
