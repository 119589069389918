import "./TypewriterSection.scss"

import React, { useRef } from "react"
import { Container, Row } from "reactstrap"
import { prop } from "ramda"
import { useChain, useTransition, animated } from "react-spring"
import { Typewriting } from "react-typewriting"

const TypeWriter = ({
  data: { head, rotatingTail },
  waitBeforeDeleteMs = 4500,
  writeSpeedMs = 100,
  deleteSpeedMs = 200,
}) => {
  const initialInternalStyle = {
    opacity: 0,
  }
  const transitionedInternalStyle = {
    opacity: 1,
  }

  const baseRow = (
    <Row className="d-flex align-items-center justify-content-center">
      <div className="typewriter-section-grid">
        <h2 className="m-0 mr-2">{head}</h2>
        <Typewriting
          strings={rotatingTail}
          waitBeforeDeleteMs={waitBeforeDeleteMs}
          writeSpeedMs={writeSpeedMs}
          deleteSpeedMs={deleteSpeedMs}
        >
          {({ currentText }) => (
            <h2 aria-label={head} className="m-0 dot dot-blue">
              {currentText}
              <span className="typewriter"></span>
            </h2>
          )}
        </Typewriting>
      </div>
    </Row>
  )

  const internalTransitionsRef = useRef()
  const internalTransitions = useTransition([baseRow], null, {
    ref: internalTransitionsRef,
    trail: 400,
    from: initialInternalStyle,
    enter: transitionedInternalStyle,
    leave: initialInternalStyle,
  })

  const section = (
    <section id="we-develop-with" className="bg-blue-dark text-white py-5">
      <Container className="py-5">
        {internalTransitions.map(({ item, props, key }) => (
          <animated.div key={key} style={props} {...item.props}></animated.div>
        ))}
      </Container>
    </section>
  )

  const initialSectionStyle = {
    opacity: 0,
  }
  const transitionedSectionStyle = {
    opacity: 1,
  }

  const sectionTransitionsRef = useRef()
  const sectionTransition = useTransition(section, prop("key"), {
    ref: sectionTransitionsRef,
    from: initialSectionStyle,
    enter: transitionedSectionStyle,
    leave: initialSectionStyle,
  })

  useChain([sectionTransitionsRef, internalTransitionsRef], [0, 0.25])

  return sectionTransition.map(({ item, props, key }) => (
    <animated.section
      key={key}
      style={props}
      {...item.props}
    ></animated.section>
  ))
}

export default TypeWriter
