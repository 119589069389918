import React from "react"
import { map, prop } from "ramda"
import { Row, Col } from "reactstrap"

const GridList = ({ items, imageList }) =>
  map(
    block => (
      <Col key={prop("label", block)} xs={12} lg={6}>
        <img
          alt={prop("label", block)}
          className="d-none d-lg-block pb-3 pt-5"
          src={imageList[prop("image", block)]}
          width="130"
        />
        <img
          alt={prop("label", block)}
          className="d-block d-sm-none pb-3 pt-5 mx-auto"
          src={imageList[prop("image", block)]}
          width="170"
        />
        <h4 className="d-block d-sm-none d-lg-block dot dot-green">
          {prop("label", block)}
        </h4>
        <h4 className="d-none d-sm-block d-lg-none dot dot-green my-4">
          {prop("label", block)}
        </h4>
        <Row>
          <Col xs={3} className="d-none d-sm-block d-lg-none">
            <img
              alt={prop("label", block)}
              className="pb-3"
              src={imageList[prop("image", block)]}
            />
          </Col>
          <Col xs={12} sm={9} lg={11}>
            <p>{prop("text", block)}</p>
          </Col>
        </Row>
      </Col>
    ),
    items
  )

export default GridList
